import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';
import axios from 'axios';

import styles from './Form.module.scss';
import GuideMessage from '../common/GuideMessage';
import Agreement from '../common/Agreement';

const cx = classNames.bind(styles);
const products = ['기프트카드', '케이크', '선물세트', '기타'];

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
      isAgree: false,
      isProcessing: false,
    };

    // Refs.
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.telInput = React.createRef();
    this.titleInput = React.createRef();
    this.contentInput = React.createRef();
  }

  render() {
    const { selectedItem, isAgree, isProcessing } = this.state;

    return (
      <div className={cx('contentWrapper')}>
        <Container>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }} className={cx('column')}>
              <input
                type="text"
                ref={this.nameInput}
                placeholder="성명"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.emailInput}
                placeholder="이메일"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.telInput}
                placeholder="전화번호 ('-' 제외 및 숫자만 입력)"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.titleInput}
                placeholder="제목"
                disabled={isProcessing}
                className={cx('field')}
              />
              <div className={cx('items')}>
                {products.map((product, i) => {
                  return (
                    <div
                      key={i}
                      className={cx('item', { selected: selectedItem === i })}
                      onClick={this.selectItem.bind(this, i)}>
                      {selectedItem === i && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                      <div className={cx('text')}>{product}</div>
                    </div>
                  );
                })}
              </div>
              <textarea
                type="text"
                ref={this.contentInput}
                placeholder="내용"
                className={cx('textarea')}
                rows={8}
                disabled={isProcessing}></textarea>
            </Col>
            <Col xs={12} lg={4.5} offset={{ lg: 0.5 }} className={cx('column')}>
              <GuideMessage>
                아티제 단체 및 기업구매는
                <br />
                총 주문금액에 따라 상이하여 세부사항은 상담을 통해 안내받으실 수 있습니다.
                <br />
                자세한 상담은 작성해주신 신청사항을 바탕으로
                <br />
                개별 연락 드립니다.
              </GuideMessage>
              <div className={cx('downloadWrapper')}>
                <a
                  href="/assets/menu.pdf"
                  target="_blank"
                  download="아티제 선물세트"
                  rel="noopener noreferrer"
                  className={cx('download')}>
                  <div className={cx('text')}>상품소개</div>
                  <Ionicon icon="md-download" fontSize="17px" className={cx('icon')} />
                </a>
              </div>
              <Agreement />
              <div className={cx('buttonWrapper')}>
                <div className={cx('checkWrapper')}>
                  <div className={cx('checkBox')} onClick={this.toggleCheck}>
                    {isAgree && <Ionicon icon="md-checkmark" fontSize="17px" />}
                  </div>
                  <div className={cx('agreeText')}>상기 개인정보 수집 및 이용에 동의합니다.</div>
                </div>
                <div className={cx('sendButton')} onClick={this.applyForm}>
                  <div className={cx('text')}>{isProcessing ? '등록 중' : '신청하기'}</div>
                  <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  selectItem = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({ selectedItem: idx });
    }
  };

  toggleCheck = () => {
    const { isAgree, isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({ isAgree: !isAgree });
    }
  };

  applyForm = () => {
    const { selectedItem, isProcessing } = this.state;

    if (isProcessing) {
      return;
    }

    // 입력내용 검증을 통과한 경우
    if (this.validateForm()) {
      this.setState({ isProcessing: true });

      axios
        .post('https://flymail.apis.flyground.co.kr/send', {
          key: '986ec6d5-dd01-4b4e-8709-d6654d5381e0',
          to: ['15a160300@bonavie.co.kr'],
          subject: this.titleInput.current.value,
          html: `
            <ul>
              <li>성명 : ${this.nameInput.current.value}</li>
              <li>이메일 : ${this.emailInput.current.value}</li>
              <li>연락처 : ${this.telInput.current.value}</li>
              <li>문의상품 : ${products[selectedItem]}</li>
            </ul>
            <br />
            <p>${this.contentInput.current.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')}</p>
          `,
        })
        .then(() => {
          alert('정상적으로 등록이 완료되었습니다.');
          navigate('/'); // 등록 완료 후 홈 이동
        });
    }
  };

  validateForm = () => {
    const { isAgree } = this.state;

    const name = this.nameInput.current.value.trim();
    const email = this.emailInput.current.value.trim();
    const tel = this.telInput.current.value.trim();
    const title = this.titleInput.current.value.trim();
    const content = this.contentInput.current.value.trim();

    // 성명
    if (name === '') {
      alert('성명을 입력해주세요.');
      this.nameInput.current.focus();
      return false;
    }

    // 이메일
    if (email === '') {
      alert('이메일을 입력해주세요.');
      this.emailInput.current.focus();
      return false;
    } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      alert('이메일 형식을 확인해주세요.');
      this.emailInput.current.focus();
      return false;
    }

    // 전화번호
    if (tel === '') {
      alert('전화번호를 입력해주세요.');
      this.telInput.current.focus();
      return false;
    } else if (!/^[0-9]+$/.test(tel)) {
      alert('전화번호 형식을 확인해주세요.');
      this.telInput.current.focus();
      return false;
    }

    // 제목
    if (title === '') {
      alert('제목을 입력해주세요.');
      this.titleInput.current.focus();
      return false;
    }

    // 내용
    if (content === '') {
      alert('내용을 입력해주세요.');
      this.contentInput.current.focus();
      return false;
    }

    // 동의
    if (!isAgree) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      return false;
    }

    return true;
  };
}

export default OrderForm;
